import React from "react"
import { graphql } from "gatsby"

import WorkGrid from "src/components/WorkGrid"

export default ({
  data: {
    work: { data },
  },
}) => {
  return <WorkGrid data={data} />
}

export const query = graphql`
  query EditorialWorkQuery {
    work: prismicWorkGrid(slugs: { eq: "editorial" }) {
      data {
        ...WorkGrid
      }
    }
  }
`
